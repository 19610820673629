import {datasGatewayInterface} from "../../../corelogic/usecases/datas/datasGatewayInterface";
import {ELocalData} from "../../../corelogic/models/entities/datas/localData";
import {EConsoData} from "../../../corelogic/models/entities/datas/consoData";
import client from "../libs/client";
import currDateToTimeLimitsConverter from "../libs/CurrDateToTimeLimitsConverter";
import {t} from "i18next";

export default class APIDatasGateway implements datasGatewayInterface {
    error: any;


    setError = (error: boolean) => {
        this.error = error;
    };


    getLocalProductionByInterval = async (participantId : number, interval : string, currentDate ?: Date,dateFrom ?: string , dateTo ?:string): Promise<ELocalData[]> => {
        const myPart: ELocalData = new ELocalData("Ma part", -1)
        const otherPart: ELocalData = new ELocalData( "Part des autres", -1)
        const surplusPart: ELocalData = new ELocalData( "Part rejetée", -1)
        const {from, to} = currDateToTimeLimitsConverter(interval, currentDate, dateFrom, dateTo)

        const {data: localDatas} = await client.get(`/participants/${participantId}/measurements/local-prod-by-interval`, {
            params: {
                interval: interval,
                dateFrom: from,
                dateTo: to,
                scope : 'Consommateur'
            }
        })
        const myPartValue = localDatas.find((elt: any) => elt.type === 'autoconso').measure[0]?._power || 0
        myPart._value = Math.round(myPartValue)
        const otherPartValue = (localDatas.find((elt: any) => elt.type === 'global').measure[0]?._power || 0 )- myPart._value
        otherPart._value = Math.round(otherPartValue);
        const surplusValue = localDatas.find((elt: any) => elt.type === 'surplus').measure[0]?._power || 0
        surplusPart._value = Math.round(surplusValue)


        return [myPart, otherPart, surplusPart]
    }

    getConso = async (participantId : number, interval: string,  currentDate ?: Date,dateFrom ?: string , dateTo ?:string): Promise<EConsoData[]> => {
        const {from, to} = currDateToTimeLimitsConverter(interval, currentDate, dateFrom, dateTo)
        function intervalMap(){
            switch(interval){
                case t('words.day') : return 'heure';
                case t('words.month') : return 'jour';
                case t('words.year') : return 'mois';
                case t('words.period') : return 'jour';
                default : return 'heure';
            }
        }
        let config:any = (interval && from && to) ? {
                params: {
                    interval: intervalMap(),
                    dateFrom: from,
                    dateTo: to,
                }
            } : {}
        const {data: autoconsoDatas} = await client.get(`/participants/${participantId}/measurements/conso-by-interval`, config);
        return autoconsoDatas.map((consoData:{date : string, power : number, powerACC : number}) => {
            return new EConsoData(consoData.date, consoData.power,  consoData.powerACC)
        })
    }

    async getDateRanges(participantId: number): Promise<{ participantId: number; from: string; to: string }[]> {
        const {data : uploadedAndSgeTiersData} = await client.get(`/participants/${participantId}/measurements/date-ranges`)
        return uploadedAndSgeTiersData.uploadedData
    }



}

