import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {consumptionGatewayInterface} from "./consumptionGatewayInterface";
import {ConsumptionActions} from "./consumptionActions";
import {GET_COORDINATES, GET_ECOCONSEILS, GET_PRODUCTION_TYPES, GET_WEATHER} from "./consumptionConstants";
import {selectCurrentParticipant, selectCurrentParticipantOperationId} from "../participants/participantsSelector";
import {Ecoconseil} from "../../models/entities/consumption/ecoconseil";
import {EParticipant} from "../../models/entities/participant/participant";
import {Coordinates} from "../../models/entities/consumption/coordinates";
import {selectCoordinates} from "./consumptionSelector";
import {DailyWeather} from "../../models/entities/consumption/dailyWeather";
import { EOperation } from '../../models/entities/operation/operation';
import { selectSelectedOperation } from '../operation/operationSelector';


function* getCoordinates() {
    try {
        const operation:EOperation = yield select(selectSelectedOperation)
        if (operation.legalRegion === 'FRANCE') {
            const participant:EParticipant = yield select(selectCurrentParticipant)
            const consumptionGatewayInterface: consumptionGatewayInterface = yield getContext('consumptionGatewayContext');
            if(participant?._enedisDetail?._city && participant?._enedisDetail?._postCode){
                const coordinates: Coordinates = yield call(consumptionGatewayInterface.getCoordinates, participant._enedisDetail._city , participant._enedisDetail._postCode);
                yield put(ConsumptionActions.setCoordinates(coordinates));
            }
            else {
                yield put(ConsumptionActions.setCoordinates(new Coordinates(null, null)));

                yield put(setError({status : 400,message : "Les coordonnées gps n'ont pas pu être récupérés, vérifiez que vous avez rempli les champs [ville] et  [code postal] sur la page 'Mon compte'. ", variant :'warning'}))
            }
        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}
function* getWeather() {
    try {
        const operationId:number = yield select(selectCurrentParticipantOperationId)
        const participant:EParticipant = yield select(selectCurrentParticipant)
        const coordinates:Coordinates = yield select(selectCoordinates)
            if(!coordinates?.latitude || !coordinates?.longitude) {
                yield put(ConsumptionActions.setWeather(null));
            }
            else {
                const consumptionGatewayInterface: consumptionGatewayInterface = yield getContext('consumptionGatewayContext');
                const weathers: DailyWeather[] = yield call(consumptionGatewayInterface.getWeather, operationId, participant._enedisDetail._city, coordinates );
                yield put(ConsumptionActions.setWeather(weathers));
            }

    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}
function* getEcoConseils() {
    try {

        const consumptionGatewayInterface: consumptionGatewayInterface = yield getContext('consumptionGatewayContext');
        const ecoConseils: Ecoconseil[] = yield call(consumptionGatewayInterface.getEcoConseils);
        yield put(
            ConsumptionActions.setEcoconseil(ecoConseils),
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* getProductionTypes() {
    try {
        const operation:EOperation = yield select(selectSelectedOperation)
        if (operation.legalRegion === 'FRANCE'){
            const consumptionGatewayInterface: consumptionGatewayInterface = yield getContext('consumptionGatewayContext');
            const typesOfProduction: string[] = yield call(consumptionGatewayInterface.getProductionTypes, operation.id);
            yield put(
                ConsumptionActions.setProductionTypes(typesOfProduction),
            );
        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}
export function* getProductionTypesSaga() {
    yield takeLatest(GET_PRODUCTION_TYPES, getProductionTypes);
}
export function* getWeatherSaga() {
    yield takeLatest(GET_WEATHER, getWeather);
}
export function* getEcoConseilsSaga() {
    yield takeLatest(GET_ECOCONSEILS, getEcoConseils);
}
export function* getCoordinatesSaga() {
    yield takeLatest(GET_COORDINATES, getCoordinates);
}
export default [getWeatherSaga,getEcoConseilsSaga, getProductionTypesSaga, getCoordinatesSaga];
