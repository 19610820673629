import {ActionWithPayload} from "../../redux/createAction";
import {
    SET_ENEDIS_DETAIL,
    SET_CURRENT_PARTICIPANT,
    SET_PARTICIPANTS,
    SET_BILLING_DETAIL,
    SET_DSO_DETAILS
} from "./participantsConstants";

const initialState = {
    participants : [],
    currentParticipant:{
        _billingDetail: {},
        _enedisDetail: {},
        _dsoDetails: {}
    },
};


export default function participantsReducer(state = initialState, action: ActionWithPayload<any>) {
    switch (action.type) {
        case SET_PARTICIPANTS: {
            return {...state, participants : action.payload.participants};
        }
        case SET_CURRENT_PARTICIPANT: {
            return {...state, currentParticipant : action.payload.currentParticipant};
        }
        case SET_ENEDIS_DETAIL : {
            return {
                ...state, currentParticipant: {
                    ...state.currentParticipant,
                    _enedisDetail : action.payload.enedisDetailUpdated
                }
            }
        }
        case SET_DSO_DETAILS : {
            return {
                ...state, currentParticipant: {
                    ...state.currentParticipant,
                    _dsoDetails : action.payload.dsoDetailsUpdated
                }
            }
        }
        case SET_BILLING_DETAIL : {
            return {
                ...state, currentParticipant: {
                    ...state.currentParticipant,
                    _billingDetail : action.payload.billingDetailUpdated
                }
            }
        }
        default:
            return state;
    }
}

