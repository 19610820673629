import {ETheme} from "../corelogic/models/entities/operation/theme";

export type PAGES = {page : string, route : string, name : string |null}

export const APPNAME:PAGES = {name: "Enoconso", page: "ENOCONSO", route: '/enoconso'}
export const HOME_PAGE:PAGES = {name: null, page: "HOME", route: APPNAME.route}
export const MY_DATAS_PAGE:PAGES = {name: "Mes données", page: "MY_DATAS", route: APPNAME.route + "/mydatas"}
export const MY_BILLS_PAGE:PAGES = {page: "MY_BILLS", route: APPNAME.route + "/mybills", name: "Mes factures"}
export const MY_CONSUMPTION_PAGE:PAGES = {page: "MY_CONSUMPTION", route: APPNAME.route + "/myconsumption", name: "Mieux consommer"}
export const MY_ACCOUNT_PAGE:PAGES = {page: "MY_ACCOUNT", route: APPNAME.route + "/myaccount", name: "Mon compte"}
export const MY_BILL_CHECK:PAGES = {page: "MY_BILL_CHECK", route: APPNAME.route + "/mybillcheck", name: "Vérifier ma facture"}
export const MY_OPERATION_PAGE:PAGES = {page: "MY_OPERATION", route: APPNAME.route + "/myoperation", name: "Mon opération"}
export const MY_DOCUMENTS_PAGE:PAGES = {page: "MY_DOCUMENTS", route: APPNAME.route + "/mydocs", name: "Mes documents"}
export const HELP_PAGE:PAGES = {page: "HELP", route: APPNAME.route + "/help", name: "FAQ - Aide"}
export const CONTACT_PAGE:PAGES = {page: "CONTACT", route: APPNAME.route +"/contact", name: "Contacter un participant"}
export const LOGIN_PAGE:PAGES = {page :"LOGIN", route:"/login", name:"Se connecter"}


// MyConsumption
export const ECO_NATURE = "ECO_NATURE"
export const ECO_MONEY = "ECO_MONEY"

export const WEATHER_SUNNY = "WEATHER_SUNNY"
export const WEATHER_CLOUDY = "WEATHER_CLOUDY"
export const WEATHER_RAINY = "WEATHER_RAINY"
export const WEATHER_THUNDER = "WEATHER_THUNDER"
export const WEATHER_SNOWY = "WEATHER_SNOWY"
export const WEATHER_MISTY = "WEATHER_MISTY"
export const WEATHER_CLEAR = "WEATHER_CLEAR"


export const JOURNEE = (t:any) => {
    return {
        MORNING:t('words.morning'),
        MIDDAY : t('words.dayTitle'),
        EVENING : t('words.evening')
    }
}
export const JOURNEES = (t:any) => {
    return {
        TODAY :t('words.today'),
        TOMORROW : t('words.tomorrow')
    }
}
// My Account

export const NAME = (t:any)=>  t('words.firstName')
export const ADDRESS = (t:any)=>t('words.address')
export const CITY =(t:any)=>t('words.city')
export const POSTCODE =(t:any)=>t('words.postCode')
export const PHONENUMBER =(t:any)=>t('words.phoneNumber')
export const PRM = (t:any)=>t('words.prmNumber')
export const EMAIL =(t:any)=>t('words.email')
export const PADT = (t:any)=>t('words.padtNumber')

// Others
export const defaultTheme : ETheme = {
    _logo : '',
    _primary : '#001E62',
    _secondary : '#E3E3E3',
}
const colors = {
    PRODUCTION: "#6abc6a",
    CONSUMPTION: "#84a6cf",
    EXCESS: "#c4c4c4",
    SELF_CONSUMPTION: "#f3b617",
    EXPENSE: "#f3b617",
    INCOME: "#84a6cf",
    SOFT_BG: "#F9F9F9",
};

const columnTypes = {
    BILLING: 'billingDetail',
    ENEDIS: 'enedisDetail',
};


const roles = {
    PARTICIPANT: 'participant',
    ORGANIZER: 'organizer',
    OBSERVER: 'observer',
};

const participantTypes = {
    CONSUMER: 'consumer',
    PRODUCER: 'producer',
};

const operationFunctionalities = {
    ACI: 'ACI',
    GES_KPI: 'gesKpi',
}

export {
    roles,
    participantTypes,
    colors,
    operationFunctionalities,
    columnTypes
};
