import {informationGatewayInterface} from "../../../corelogic/usecases/information/informationGatewayInterface";
import {TEnoField} from "../../../corelogic/models/entities/information/enoField";
import client from "../libs/client";
import {t} from "i18next";

export default class APIInformationGateway implements informationGatewayInterface {
    error: any;


    setError = (error: boolean) => {
        this.error = error;
    };




    async getOperationInformation(operationId: number): Promise<TEnoField[][]> {
        let operationInformations: TEnoField[][] = []
        const {data: operation} = await client.get(`/operations/${operationId}`)
        operationInformations.push([{_type: t('words.name'), _value: operation.name || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t('words.operationId') , _value: operation.id || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t('words.dso') , _value: operation.dso || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t(`words.${operation.dso}.dsoOperationNumber`) , _value: operation.dsoIdentifier || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t('words.legalStatus'), _value: operation.pmoLegalStatusId || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t('words.address') , _value: operation.pmoAddress || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t('words.city') , _value: operation.pmoCity || t('words.notFilled'), _editable: false}, {_type: "Code postal", _value: operation.pmoPostCode || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t('words.representantName') , _value: operation.representativeName || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t('words.representantPhone'), _value: operation.representativePhoneNumber || t('words.notFilled'), _editable: false}])
        operationInformations.push([{_type: t('words.representantEmail') , _value: operation.representativeEmail || t('words.notFilled'), _editable: false}])
        return operationInformations
    }




}
