import {call, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {setError} from "../error/errorActions"
import {
    ADD_ONE_TIME_UNIT,
    GET_CONSODATA,
    GET_LATEST_CONSODATA,
    GET_LOCALDATA,
    GET_MEASUREMENT_RANGE,
    SUBTRACT_ONE_TIME_UNIT
} from "./datasConstants";
import {datasGatewayInterface} from "./datasGatewayInterface";
import {selectCurrentParticipantId, selectUserParticipants} from "../participants/participantsSelector";
import {DatasActions} from "./datasActions";
import {
    selectDateFrom,
    selectDateTo,
    selectSelectedDatasCurrentDate,
    selectSelectedDatasInterval
} from "./datasSelector";
import {EConsoData} from "../../models/entities/datas/consoData";
import {ELocalData} from "../../models/entities/datas/localData";
import InMemoryDatasGateway from '../../../adapters/secondary/gateways/InMemoryDatasGateway';
import {handleErrorMessage} from "../../../adapters/primary/libs/handleErrorMessage";
import moment from "moment";
import {selectLatestDateWithData} from "../operation/operationSelector";
import {t} from "i18next";
function* getConso(): any {
    try {
        const participantId = yield select(selectCurrentParticipantId)
        const interval = yield select(selectSelectedDatasInterval)
        const currentDate = yield select(selectSelectedDatasCurrentDate)
        const dateFrom = yield select(selectDateFrom)
        const dateTo = yield select(selectDateTo)
        const datasGatewayInterface: datasGatewayInterface = yield getContext('datasGatewayContext');
        const consoDatas: EConsoData[] = yield call(datasGatewayInterface.getConso, participantId, interval, currentDate, dateFrom, dateTo);
        // handle when data fetched is not valuable to display
        if (consoDatas.length === 0) {
            const dummyValues: datasGatewayInterface = new InMemoryDatasGateway()
            yield put(DatasActions.setConsoDataIsReal(false))
            yield put(DatasActions.setConsoData(dummyValues.getConso(participantId, interval, currentDate, dateFrom, dateTo)));
        } else {
            yield put(DatasActions.setConsoDataIsReal(true))
            yield put(DatasActions.setConsoData(consoDatas));
        }

    } catch (error:any) {
        yield put(
            setError({
                status: error?.response?.status,
                message: "[GET ConsoDatas] : " + handleErrorMessage(error?.response?.status)
            })
        );
        console.error(error);
    }
}

function* getLatestConso(): any {
    try {
        const participantId = yield select(selectCurrentParticipantId)
        const interval = yield select(selectSelectedDatasInterval)
        const latestDateWithData:string = yield select(selectLatestDateWithData)
        const datasGatewayInterface: datasGatewayInterface = yield getContext('datasGatewayContext');
        const currentDate = new Date(latestDateWithData)
        const consoDatas: EConsoData[] = yield call(datasGatewayInterface.getConso, participantId, interval, currentDate);

        if (consoDatas.length === 0) {
            const dummyValues: datasGatewayInterface = new InMemoryDatasGateway()
            yield put(DatasActions.setConsoDataIsReal(false))
            yield put(DatasActions.setConsoData(dummyValues.getConso(participantId, interval)));
            yield put(DatasActions.setCurrentDate(new Date(Date.now())))
        } else {
            yield put(DatasActions.setConsoDataIsReal(true))
            yield put(DatasActions.setConsoData(consoDatas));
            const newCurrentDate = new Date(consoDatas[Math.round(consoDatas.length / 2)].date)
            yield put(DatasActions.setCurrentDate(new Date(newCurrentDate)))

        }


    } catch (error:any) {
        console.error(error);
        yield put(
            setError({
                status: error.response.status,
                message: "[GET ConsoDatas] : " + handleErrorMessage(error.response.status)
            })
        );
    }
}


function checkIfLocalProductionValuesAreReal(localDatas: ELocalData[]): boolean {
    // if every values returned are -1, then those values are not displayable
    let checkIfReal: boolean = false
    localDatas.forEach((localData) => {
        if (localData._value !== -1) checkIfReal = true
    })
    return checkIfReal
}

function* getLocalProduction(): any {
    try {
        const participantId = yield select(selectCurrentParticipantId)
        const interval = yield select(selectSelectedDatasInterval)
        const currentDate = yield select(selectSelectedDatasCurrentDate)
        const dateFrom = yield select(selectDateFrom)
        const dateTo = yield select(selectDateTo)
        const datasGatewayInterface: datasGatewayInterface = yield getContext('datasGatewayContext');
        const localDatas: ELocalData[] = yield call(datasGatewayInterface.getLocalProductionByInterval, participantId, interval,currentDate, dateFrom, dateTo);
        // handle when data fetched is not valuable to display
        if (checkIfLocalProductionValuesAreReal(localDatas)) {
            yield put(DatasActions.setLocalDataIsReal(true))
            yield put(DatasActions.setLocalData(localDatas))
        } else {
            const dummyValues: datasGatewayInterface = new InMemoryDatasGateway()
            yield put(DatasActions.setLocalDataIsReal(false))
            yield put(DatasActions.setLocalData(dummyValues.getLocalProductionByInterval(participantId, interval, currentDate, dateFrom, dateTo)));
        }
    } catch (error:any) {
        yield put(
            setError({
                status: error?.response?.status,
                message: "[GET LocalProductionDatas] : " + handleErrorMessage(error?.response?.status)
            })
        );
        console.error(error);
    }
}

function* addOneTimeUnit() {

    function currentDatePlusOneTimeUnit(dateToAdd: Date, interval: string): Date {
        let newDate = dateToAdd
        switch (interval) {
            case t('words.day') :
                newDate.setDate(newDate.getDate() + 1);
                break;
            case t('words.month') :
                newDate.setMonth(newDate.getMonth() + 1);
                break;
            case t('words.year') :
                newDate.setMonth(newDate.getMonth() + 12);
                break;
            default :
                break;
        }
        return newDate
    }

    try {
        const interval: string = yield select(selectSelectedDatasInterval)
        const currentDate: Date = yield select(selectSelectedDatasCurrentDate)
        const newDate = currentDatePlusOneTimeUnit(currentDate, interval)
        yield put(DatasActions.setCurrentDate(new Date(newDate)))

    } catch (error:any) {
        yield put(
            setError({
                status: error.response.status,
                message: "[addOneTimeUnitSaga] : " + handleErrorMessage(error.response.status)
            })
        );
        console.error(error);
    }
}

function* subtractOneTimeUnit() {

    function currentDateMinusOneTimeUnit(dateToAdd: Date, interval: string): Date {
        let newDate = dateToAdd

        switch (interval) {
            case t('words.day') :
                newDate.setDate(newDate.getDate() - 1);
                break;
            case t('words.month') :
                newDate.setMonth(newDate.getMonth() - 1);
                break;
            case t('words.year') :
                newDate.setMonth(newDate.getMonth() - 12);
                break;
            default :
                break;
        }
        return newDate
    }

    try {
        const interval: string = yield select(selectSelectedDatasInterval)
        const currentDate: Date = yield select(selectSelectedDatasCurrentDate)
        const newDate = currentDateMinusOneTimeUnit(currentDate, interval)
        yield put(DatasActions.setCurrentDate(new Date(newDate)))

    } catch (error:any) {
        yield put(
            setError({
                status: error.response.status,
                message: "[addOneTimeUnitSaga] : " + handleErrorMessage(error.response.status)
            })
        );
        console.error(error);
    }
}
function* getMeasurementRange(action : any){
    const consolidateRanges = (dates:any) => {
        let consolidatedRange = dates.length > 0 ? dates.reduce((acc:any, curr:any) => ({
            from: acc.from < curr.from ? acc.from : curr.from,
            to: acc.to > curr.to ? acc.to : curr.to
        })) : null;
        const range = {
            from: consolidatedRange && moment(consolidatedRange.from),
            to: consolidatedRange && moment(consolidatedRange.to),
        };

        return range;
    };

    try {
        let participantId:number = yield select(selectCurrentParticipantId)
        const participants:any[] = yield select(selectUserParticipants)
        const {PRM} = action.payload
        if(PRM){
            participantId = participants.find((participant)=> participant._enedisDetail._prm === PRM)._id
        }
        const datasGatewayInterface: datasGatewayInterface = yield getContext('datasGatewayContext');
        const uploadedDatesRange: any[] = yield call(datasGatewayInterface.getDateRanges, participantId);
        yield put(DatasActions.setUploadedDateRange(consolidateRanges(uploadedDatesRange).from, consolidateRanges(uploadedDatesRange).to))

    } catch (error:any) {
        yield put(
            setError({
                status: error?.response?.status,
                message: "[getMeasurementRange] : " + handleErrorMessage(error?.response?.status)
            })
        );
        console.error(error);
    }
}
export function* subtractOneTimeUnitSaga() {
    yield takeLatest(SUBTRACT_ONE_TIME_UNIT, subtractOneTimeUnit);
}
export function* getMeasurementRangeSaga() {
    yield takeLatest(GET_MEASUREMENT_RANGE, getMeasurementRange);
}

export function* addOneTimeUnitSaga() {
    yield takeLatest(ADD_ONE_TIME_UNIT, addOneTimeUnit);
}

export function* getConsoDataSaga() {
    yield takeLatest(GET_CONSODATA, getConso);
}

export function* getLatestConsoDataSaga() {
    yield takeLatest(GET_LATEST_CONSODATA, getLatestConso);
}

export function* getLocalDataSaga() {
    yield takeLatest(GET_LOCALDATA, getLocalProduction);
}


export default [getLocalDataSaga, addOneTimeUnitSaga, subtractOneTimeUnitSaga, getConsoDataSaga, getLatestConsoDataSaga, getMeasurementRangeSaga];



