import React, {Component, Fragment} from 'react';
import { withTranslation } from 'react-i18next';
import {
    Button,
    createStyles,
    CssBaseline,
    FormControl,
    Input,
    InputLabel,
    Link,
    Paper,
    TextField,
    Theme,
    Typography,
    withStyles
} from '@material-ui/core'
import client from '../../../../../secondary/libs/client';
import {ProviderContext, withSnackbar} from "notistack";
import BrowserWarning from "./BrowserWarning";
import MuiTheme from "../../../../../../config/MuiTheme";
import { useTranslation } from 'react-i18next';

const enoTheme = MuiTheme();
const styles = (theme: Theme) => createStyles({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: enoTheme.spacing(3),
        marginRight: enoTheme.spacing(3),
        [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    typo: {
        marginTop: theme.spacing(1),
        cursor: 'pointer',
        fontSize: "12px",
        color : enoTheme.palette.greyToWhite.dark,

    },
    submit: {
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.primary.main,


    },
    paperSign: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: enoTheme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    title: {
        fontWeight: 900,
        fontSize: "25px",
        textTransform: "uppercase",
        color: enoTheme.palette.primary.main,
        paddingBottom: "5vh"
    },
    inputLabel: {
        marginTop: -5,
        color: enoTheme.palette.primary.main
    },

});

type TProps = { onSubmit?: any, classes?: any, enqueueSnackbar?: any, t?: any } & ProviderContext
type TState = { forgotPassword: boolean, username: string, askedConfCode: boolean, password: string, confCode: string }

class SignIn extends Component<TProps, TState> {

    state = {
        forgotPassword: false,
        askedConfCode: false,
        username: '',
        password: '',
        confCode: '',
    };
    togglePassword = () => {
        this.setState(prevState => ({forgotPassword: !prevState.forgotPassword}));
    }

    handleChange = (name: string) => (event: any) => {
        this.setState({
            askedConfCode: this.state.askedConfCode, confCode:  this.state.confCode, forgotPassword: this.state.forgotPassword, password: this.state.password, username: this.state.username,
            [name]: event.target.value
        })
    }
    getConfirmationCode = () => {
        const {t} = useTranslation();
        client.post('/users/forgot-password', {email: this.state.username})
            .then(() => {
                this.setState({askedConfCode: true})
            })
            .catch(() => {
                this.props.enqueueSnackbar(t('text.unknownUsername'), {
                    autoHideDuration: 7000,
                    variant: 'warning'
                });
            });
    }

    submitConfCode = () => {
        const {t} = useTranslation();
        client
            .post('/users/update-pw-with-code', {
                email: this.state.username,
                recoveryCode: this.state.confCode,
                password: this.state.password
            })
            .then(() => {
                this.setState({
                    forgotPassword: false,
                    askedConfCode: false
                });
            })
            .catch(() => {
                this.props.enqueueSnackbar(t('text.invalidConfirmationCode'), {
                    autoHideDuration: 7000,
                    variant: 'warning'
                });
            });
    }

    renderForgotPassword() {
        const {classes, t} = this.props;
        if (this.state.askedConfCode) {
            return (
                <Fragment>
                    <Typography component="h1" variant="h5">
                        {t('text.forgotPassword')}
                    </Typography>
                    <Typography variant="subtitle1">
                        {t('text.checkYourSpamFolder')}
                    </Typography>
                    <TextField
                        margin="normal" required fullWidth autoFocus
                        label={t('words.username')}
                        onChange={this.handleChange('username')}
                        value={this.state.username || ''}
                    />
                    <TextField
                        margin="normal" required fullWidth
                        label={t('text.confirmationCode')}
                        onChange={this.handleChange('confCode')}
                        value={this.state.confCode || ''}
                    />
                    <TextField
                        margin="normal" required fullWidth
                        label={t('words.newPassword')}
                        type="password"
                        onChange={this.handleChange('password')}
                        value={this.state.password || ''}
                    />
                    <Button
                        onClick={this.submitConfCode}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {t('text.savePassword')}
                    </Button>
                </Fragment>);
        } else {
            return (
                <Fragment>
                    <Typography component="h1" variant="h5">
                        {t('words.forgotPassword')}
                    </Typography>
                    <TextField
                        margin="normal" required fullWidth autoFocus
                        label={t('words.emailAddress')}
                        onChange={this.handleChange('username')}
                        value={this.state.username || ''}
                    />
                    <Button
                        onClick={this.getConfirmationCode}
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        {t('text.receiveConfirmationCode')}
                    </Button>
                </Fragment>
            );
        }
    }

    render() {
        const {classes, onSubmit, t} = this.props;
        return (
            <main className={classes.main}>
                <CssBaseline/>
                <Paper className={classes.paperSign}>
                    {this.state.forgotPassword
                        ? this.renderForgotPassword()
                        : <Fragment>
                            <span className={classes.title}>{t('words.connexion')}</span>

                            <form className={classes.form} onSubmit={onSubmit}>
                                <FormControl margin="normal"  required fullWidth>
                                    <InputLabel  htmlFor="username"  className={classes.inputLabel}>
                                        {t('words.username')}
                                    </InputLabel>
                                    <Input id="username" name="username" autoComplete="username" autoFocus/>
                                </FormControl>
                                <FormControl margin="normal" required fullWidth>
                                    <InputLabel htmlFor="password" className={classes.inputLabel}>
                                        {t('words.password')}
                                    </InputLabel>
                                    <Input name="password" type="password" id="password"
                                           autoComplete="current-password"/>
                                </FormControl>
                                <BrowserWarning/>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size={"large"}
                                    className={classes.submit}
                                >
                                    {t('text.signIn')}
                                </Button>
                            </form>
                            <Link className={classes.typo} onClick={this.togglePassword}>
                                {t('text.forgotPassword')}
                            </Link>
                        </Fragment>
                    }
                </Paper>
            </main>
        );
    }
}

export default withStyles(styles)(withTranslation()(withSnackbar(SignIn)));

