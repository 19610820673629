import {all, call, getContext, put, select, takeLatest} from 'redux-saga/effects';
import {
    getOperations as getOperationsAction, setLatestDateWithData,
    setOperationInfos,
    setOperations, setParticipantOperation,
    setSelectedOperation
} from "./operationActions";
import {setError} from "../error/errorActions"
import {
    GET_LATEST_DATE_WITH_DATA,
    GET_OPERATION_INFOS,
    GET_OPERATION_PARTICIPANTS,
    GET_OPERATIONS,
    GET_PARTICIPANT_OPERATION,
    UPDATE_OPERATION,
} from "./operationConstants";
import {selectUser} from "../authentication/authenticationSelector";
import {defaultTheme, roles} from "../../../config/app-config";
import {operationGatewayInterface} from "./operationGatewayInterface";
import {selectCurrentParticipantOperationId} from "../participants/participantsSelector";
import {selectLatestDateWithData} from "./operationSelector";


function* getOperations() {
    try {
        const { role } = yield select(selectUser);
        let operations : any[] = [];
        const operationGatewayInterface:operationGatewayInterface = yield getContext('operationGatewayContext');
        switch (role) {
            case 'participant':
                const participants:any[] = yield call(operationGatewayInterface.getParticipantsByJwt);
            const operationsIds:any[] = [...new Array(participants.map(part => part.operationId))]; // TODO j'ai changer le type à Array[number] au lieu de Set[Number], voir si pas d'influence
                 operations = yield call(operationGatewayInterface.getOperations, operationsIds);
                operations.forEach(operation => {
                    operation.producers = participants.filter(part => part.operationId === operation.id);
                });
                break;
            case 'observer':
                operations  = yield call(operationGatewayInterface.getObserverOperations);
                break;
            case 'organizer':
                const organizer:{id : number, name:string, userId:number} = yield call(operationGatewayInterface.getOrganizerByJwt);
                operations = yield call(operationGatewayInterface.getOrganizerOperations, organizer.id);
                break;
            default:
        }
        const getPresignedLogosCalls = operations.map(operation => call(operationGatewayInterface.getPresignedLogo, operation));
        yield all(getPresignedLogosCalls);
        yield put(
            setOperations(operations),
        );
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* getOperationParticipantsAndObservers(action:any):any{
    try {
        const operationGatewayInterface:operationGatewayInterface = yield getContext('operationGatewayContext');
        const { operation } = action;
        if (operation) {
            const user = yield select(selectUser);
            if (user._role !== roles.PARTICIPANT) {
                const participantsAndObserversCalls = [call(operationGatewayInterface.getOperationParticipants, operation.id)];
                if (user._role !== roles.OBSERVER) {
                    participantsAndObserversCalls.push(call(operationGatewayInterface.getOperationObservers, operation.id))
                }
                const [participants, observers] = yield all(participantsAndObserversCalls);
                operation.observers = observers || [];
                operation.consumers = [];
                operation.producers = [];
                participants.forEach((participant: { energyType: string; }) => (participant.energyType === 'consumer' ? operation.consumers : operation.producers).push(participant));
            }
            yield put(
                setSelectedOperation(operation),
            );
        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* updateOperation(action:any):any {
    try {
        const {operation, formData} = action
        const operationGatewayInterface:operationGatewayInterface = yield getContext('operationGatewayContext');
        const updateCalls = []
        updateCalls.push(call(operationGatewayInterface.updateOperation, operation));
        if(formData) {
            updateCalls.push(call(operationGatewayInterface.uploadLogo, operation, formData))
        }
        const [updatedOperation, file] = yield all(updateCalls);
        yield put(
            getOperationsAction(roles.PARTICIPANT) // TODO ajout de l'argument roles.participant pour compilation mais aucune idée de ce qu'il faudrait mettre
        )
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

//My Operation
function* getOperationInfos():any {
    try {
        const operationId = yield select(selectCurrentParticipantOperationId)
        const latestDayWithDataAvailable : string = yield select(selectLatestDateWithData)
        const operationGatewayInterface:operationGatewayInterface = yield getContext('operationGatewayContext');
        if (operationId) {
            const operationInfos = yield call(operationGatewayInterface.getOperationInfos, operationId, latestDayWithDataAvailable)
            yield put(
                setOperationInfos(operationInfos)
            )
        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* getParticipantOperation():any{
    try {
        const operationId = yield select(selectCurrentParticipantOperationId)

        const operationGatewayInterface:operationGatewayInterface = yield getContext('operationGatewayContext');
        if (operationId) {
            const operation = yield call(operationGatewayInterface.getParticipantOperation, operationId)
            if(operation.theme === null) {
                operation.theme = defaultTheme
            }
            yield put(
                setParticipantOperation(operation)
            )
        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

function* getLatestDateWithData() : any {
    try {
        const operationId = yield select(selectCurrentParticipantOperationId)
        const operationGatewayInterface:operationGatewayInterface = yield getContext('operationGatewayContext');
        if (operationId) {
            const latestDay = yield call(operationGatewayInterface.getLatestDateWithData, operationId)
            yield put(
                setLatestDateWithData(latestDay)
            )
        }
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}

export function* getOperationInfosSaga(){
    yield takeLatest(GET_OPERATION_INFOS, getOperationInfos)
}

export function* getOperationsSaga() {
    yield takeLatest(GET_OPERATIONS, getOperations);
}

export function* getOperationParticipantsSaga() {
    yield takeLatest(GET_OPERATION_PARTICIPANTS, getOperationParticipantsAndObservers);
}


export function* updateOperationSaga() {
    yield takeLatest(UPDATE_OPERATION, updateOperation);
}
export function* getLatestDateWithDataSaga() {
    yield takeLatest(GET_LATEST_DATE_WITH_DATA, getLatestDateWithData);
}

export function* getParticipantOperationSaga(){
    yield takeLatest(GET_PARTICIPANT_OPERATION, getParticipantOperation)
}
export default [getLatestDateWithDataSaga, getParticipantOperationSaga, getOperationsSaga, getOperationParticipantsSaga, updateOperationSaga, getOperationInfosSaga];



