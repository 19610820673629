export class DsoDetails{

    _dsoIdentifier : string;
    _city : string;
    _postCode : string;
    _address:string;
    _name:string;

    constructor( dsoIdentifier : string, city : string, postCode :string, address :string, name : string) {
        this._dsoIdentifier = dsoIdentifier;
        this._city=city;
        this._postCode = postCode;
        this._address = address
        this._name = name
    }
    serialize() {
        return {
            dsoIdentifier: this._dsoIdentifier,
            city:this._city,
            postCode : this._postCode,
            address:this._address,
            name:this._name
        }
    }

}
