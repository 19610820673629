import {BillingDetail} from "./billingDetail";
import { DsoDetails } from './dsoDetails';
import {EnedisDetail} from "./enedisDetail";

export class EParticipant{

    _id:number;
    _name : string;
    _activationRange : Date[]
    _userId : number;
    _operationId:number;
    _billingDetail:BillingDetail;
    _enedisDetail:EnedisDetail;
    _dsoDetails:DsoDetails;


    constructor(id:number,name:string, activationRange : Date[], userId :number, operationId:number, billingDetail : BillingDetail, enedisDetail : EnedisDetail, dsoDetails : DsoDetails) {
        this._id = id;
        this._name=name;
        this._activationRange = activationRange;
        this._userId=userId;
        this._operationId=operationId;
        this._billingDetail = billingDetail;
        this._enedisDetail = enedisDetail;
        this._dsoDetails = dsoDetails;
    }

}
