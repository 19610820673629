import {consumptionGatewayInterface} from "../../../corelogic/usecases/consumption/consumptionGatewayInterface";
import {Weather} from "../../../corelogic/models/entities/consumption/weather";
import {Ecoconseil} from "../../../corelogic/models/entities/consumption/ecoconseil";
import client from "../libs/client";
import axios from "axios";
import {
    JOURNEE,
    JOURNEES,
    WEATHER_CLEAR,
    WEATHER_CLOUDY,
    WEATHER_MISTY,
    WEATHER_RAINY,
    WEATHER_SNOWY,
    WEATHER_SUNNY,
    WEATHER_THUNDER
} from "../../../config/app-config";
import {DailyWeather} from "../../../corelogic/models/entities/consumption/dailyWeather";
import {Coordinates} from "../../../corelogic/models/entities/consumption/coordinates";
import {t} from "i18next";


function weatherTypeENtoFR(weatherEnglish: string): string {
    switch (weatherEnglish) {
        case "Clouds" :
            return WEATHER_CLOUDY;
        case "Rain" || "Drizzle" :
            return WEATHER_RAINY;
        case "Sun" :
            return WEATHER_SUNNY;
        case "Thunderstorm" :
            return WEATHER_THUNDER;
        case "Snow" :
            return WEATHER_SNOWY;
        case "Mist" || "Smoke" || "Haze" || "Dust" || "Fog" || "Sand" || "Ash" || "Squall" || "Tornado" :
            return WEATHER_MISTY;
        case "Clear" :
            return WEATHER_CLEAR;
        default :
            return WEATHER_SUNNY
    }
}


export default class APIConsumptionGateway implements consumptionGatewayInterface {
    error: any;



    setError = (error: boolean) => {
        this.error = error;
    };

    getProductionTypes = async (operationId: number): Promise<string[]> => {
        const {data: typesOfProduction} = await client.get(`/operations/${operationId}/enedis-details/production-types`);
        return typesOfProduction
    }

    private static getAccurateWeather(weatherByHour: any, boundary: number[], hoursLeftToday: number): string {
        return (hoursLeftToday >= boundary[1]) ?
            weatherTypeENtoFR(weatherByHour[hoursLeftToday - boundary[1] ].weather[0].main)
            : weatherTypeENtoFR(weatherByHour[hoursLeftToday].weather[0].main)

    }

    private fillWeather(weatherByHour: any, hoursLeftToday: number, city: string, journees: string): Weather[] {
        const eveningBoundaries: number[] = [2, 5, 7]
        const dailyBoundaries: number[] = [7, 10, 14]
        const morningBoundaries: number[] = [14, 16, 18]
        let offset: number = JOURNEES(t).TODAY === journees ? 0 : 24
        hoursLeftToday +=offset
        let sunAsleep: boolean = hoursLeftToday <= 2
        let eveningSun: boolean = hoursLeftToday > eveningBoundaries[0] && hoursLeftToday <= eveningBoundaries[2]
        let dailySun: boolean = hoursLeftToday > dailyBoundaries[0] && hoursLeftToday <= dailyBoundaries[2]
        let morningSun: boolean = hoursLeftToday > morningBoundaries[0]

        switch (true) {
            case (sunAsleep && offset ===0) :
                return [new Weather("WEATHER_CLOUDY", city, JOURNEE(t).MORNING, false), new Weather("WEATHER_CLOUDY", city, JOURNEE(t).MIDDAY, false), new Weather("WEATHER_CLOUDY", city, JOURNEE(t).EVENING, false)];
            case (eveningSun && offset === 0)  :
                return [
                    new Weather("WEATHER_CLOUDY", city, JOURNEE(t).MORNING, false),
                    new Weather("WEATHER_CLOUDY", city, JOURNEE(t).MIDDAY, false),
                    new Weather(APIConsumptionGateway.getAccurateWeather(weatherByHour, eveningBoundaries, hoursLeftToday), city, JOURNEE(t).EVENING, true)];

            case (dailySun && offset === 0)  :
                return [
                    new Weather("WEATHER_CLOUDY", city, JOURNEE(t).MORNING, false),
                    new Weather(APIConsumptionGateway.getAccurateWeather(weatherByHour, dailyBoundaries, hoursLeftToday), city, JOURNEE(t).MIDDAY, true),
                    new Weather(APIConsumptionGateway.getAccurateWeather(weatherByHour, eveningBoundaries, hoursLeftToday), city, JOURNEE(t).EVENING, true)];

            case (morningSun)  :
                return [
                    new Weather(APIConsumptionGateway.getAccurateWeather(weatherByHour, morningBoundaries, hoursLeftToday), city, JOURNEE(t).MORNING, true),
                    new Weather(APIConsumptionGateway.getAccurateWeather(weatherByHour, dailyBoundaries, hoursLeftToday), city, JOURNEE(t).MIDDAY, true),
                    new Weather(APIConsumptionGateway.getAccurateWeather(weatherByHour, eveningBoundaries, hoursLeftToday), city, JOURNEE(t).EVENING, true)];
        }
        return []
    }

    getCoordinates  = async (city : string, postCode : string):Promise<Coordinates> => {
        const url_geo: string = `https://api-adresse.data.gouv.fr/search/?q=${city}&postcode=${postCode}&limit=1`
        let latitude = null
        let longitude = null
        try {
            const geoResponse = await axios.get(url_geo)
            latitude = geoResponse.data.features[0].geometry.coordinates[1]
            longitude = geoResponse.data.features[0].geometry.coordinates[0]
        } catch (error:any) {
            console.log(error)
        }
        return new Coordinates(longitude, latitude)
    }
    getWeather = async (operationId: number, city: string, coordinates : Coordinates): Promise<DailyWeather[]> => {

        const lat = `lat=${coordinates.latitude}`;
        const lon = `&lon=${coordinates.longitude}`;
        const ex = `&exclude=daily,minutely,address`;
        const url = process.env.REACT_APP_WEATHER_API_URL;
        const appID = process.env.REACT_APP_WEATHER_API_ID;
        const units = '&units=metric'
        const lang = '&lang=fr'

        let weather: DailyWeather[] = [new DailyWeather(JOURNEES(t).TODAY,[]), new DailyWeather(JOURNEES(t).TOMORROW,[])]

        try {
            const response = await axios.get(url + lat + lon + units + ex + '&APPID=' + appID + lang)
            const weatherByHour = response.data.hourly
            const hoursLeftToday: number = 24 - new Date(Date.now()).getHours()
            weather.forEach((weather: DailyWeather) => {
                weather.weather = this.fillWeather(weatherByHour, hoursLeftToday, city, weather.date)
            })
        } catch (error:any) {
            console.log(error)
        }

        return Promise.resolve(weather)

    }
    getEcoConseils = async (): Promise<Ecoconseil[]> => {
        const {data: advices} = await client.get(`/advices/`);
        return advices.map((advice: { category: string, title: string, description: string, pros: string }) => {
            return new Ecoconseil(advice.category, advice.title, advice.description, advice.pros)
        })
    }
}
