import {call, getContext, put, select, takeLatest} from "redux-saga/effects";
import {setError} from "../error/errorActions";
import {informationGatewayInterface} from "./informationGatewayInterface";
import {InformationActions} from "./informationActions";
import {GET_OPERATION_INFORMATION} from "./informationConstants";
import {selectCurrentParticipantOperationId} from "../participants/participantsSelector";
import {TEnoField} from "../../models/entities/information/enoField";

function* getOperationInformation() {
    try {
        const operationId:number = yield select(selectCurrentParticipantOperationId)
        const informationGatewayInterface: informationGatewayInterface = yield getContext('informationGatewayContext');
        const operationInfos: TEnoField[][] = yield call(informationGatewayInterface.getOperationInformation , operationId );
        yield put(InformationActions.setOperationInformation(operationInfos));
    } catch (error:any) {
        yield put(
            setError({status: error.response && error.response.status}),
        );
        console.error(error);
    }
}



export function* getOperationInformationSaga() {
    yield takeLatest(GET_OPERATION_INFORMATION, getOperationInformation);
}

export default [getOperationInformationSaga];
