import React, {RefObject, Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './config/reportWebVitals';
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import {SnackbarProvider} from "notistack";
import Button from "@material-ui/core/Button";
import ErrorBoundary from "./adapters/primary/ui/Components/global/Errors/ErrorBoundary";
import configureStore from "./corelogic/redux/configureStore";
import {createBrowserHistory} from 'history'
import App from "./adapters/primary/ui/App";
import 'moment/locale/fr';
import moment from 'moment';
import APINavigationGateway from "./adapters/secondary/gateways/APINavigationGateway";
import APIAuthenticationGateway from "./adapters/secondary/gateways/APIAuthenticationGateway";
import APIOperationGateway from "./adapters/secondary/gateways/APIOperationGateway";
import APIConsumptionGateway from "./adapters/secondary/gateways/APIConsumptionGateway";
import APIHelpGateway from "./adapters/secondary/gateways/APIHelpGateway";
import APIInformationGateway from "./adapters/secondary/gateways/APIInformationGateway";
import APIContactGateway from "./adapters/secondary/gateways/APIContactGateway";
import APIParticipantsGateway from "./adapters/secondary/gateways/APIParticipantsGateway";
import APIDatasGateway from "./adapters/secondary/gateways/APIDatasGateway";
import APIBillingGateway from "./adapters/secondary/gateways/APIBillingGateway";
import APIBillCheckGateway from "./adapters/secondary/gateways/APIBillCheckGateway";
import APIDocumentGateway from "./adapters/secondary/gateways/APIDocumentGateway";
import InMemoryAuthenticationGateway from "./adapters/secondary/gateways/InMemoryAuthenticationGateway";
import InMemoryOperationGateway from "./adapters/secondary/gateways/InMemoryOperationGateway";
import InMemoryConsumptionGateway from "./adapters/secondary/gateways/InMemoryConsumptionGateway";
import InMemoryDocumentGateway from "./adapters/secondary/gateways/InMemoryDocumentGateway";
import InMemoryInformationGateway from "./adapters/secondary/gateways/InMemoryInformationGateway";
import InMemoryContactGateway from "./adapters/secondary/gateways/InMemoryContactGateway";
import InMemoryParticipantsGateway from "./adapters/secondary/gateways/InMemoryParticipantsGateway";
import InMemoryHelpGateway from "./adapters/secondary/gateways/InMemoryHelpGateway";
import InMemoryDatasGateway from "./adapters/secondary/gateways/InMemoryDatasGateway";
import InMemoryBillingGateway from "./adapters/secondary/gateways/InMemoryBillingGateway";
import InMemoryBillCheckGateway from "./adapters/secondary/gateways/InMemoryBillCheckGateway";
import './config/i18n'

moment.locale('fr');
const history = createBrowserHistory()
const useInMemory:boolean = process.env.REACT_APP_USE_INMEMORY === 'enabled'
const store = configureStore({
    history,
    sagaContext: {
        authenticationGatewayContext: useInMemory  ? new InMemoryAuthenticationGateway() : new APIAuthenticationGateway(),
        operationGatewayContext: useInMemory ? new InMemoryOperationGateway() : new  APIOperationGateway(),
        consumptionGatewayContext :useInMemory ? new InMemoryConsumptionGateway() :  new APIConsumptionGateway(),
        documentGatewayContext : useInMemory ? new InMemoryDocumentGateway() : new APIDocumentGateway(),
        informationGatewayContext : useInMemory ? new InMemoryInformationGateway() : new  APIInformationGateway(),
        contactGatewayContext :useInMemory ? new InMemoryContactGateway() : new  APIContactGateway(),
        participantsGatewayContext :useInMemory  ? new InMemoryParticipantsGateway() :  new  APIParticipantsGateway(),
        helpGatewayContext : useInMemory ? new InMemoryHelpGateway() : new APIHelpGateway(),
        datasGatewayContext :useInMemory ? new InMemoryDatasGateway(): new  APIDatasGateway(),
        billingGatewayContext :useInMemory ? new InMemoryBillingGateway() :  new  APIBillingGateway(), // TODELETE FOR NOW, doesnt exist in HomePage v2
        navigationGatewayContext: new APINavigationGateway(),
        billCheckGatewayContext : useInMemory? new InMemoryBillCheckGateway() : new  APIBillCheckGateway()
    }
});

const notistackRef: RefObject<any> = React.createRef();

const onClickClose = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
};
ReactDOM.render(
    <React.StrictMode>
        <style>
            @import url('https://fonts.googleInMemorys.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap');
            @import url('https://fonts.googleInMemorys.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
        </style>
        <Suspense fallback={<div> </div>}>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                        <SnackbarProvider
                            ref={notistackRef}
                            action={(key) => <Button
                                style={{color: 'white'}}
                                size="small"
                                onClick={onClickClose(key)}>
                                Ok
                            </Button>}
                            maxSnack={10}
                        >
                            <ErrorBoundary>
                            <App/>
                            </ErrorBoundary>
                        </SnackbarProvider>
                </ConnectedRouter>
            </Provider>
        </Suspense>
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log); // performance relayer

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister(); // todo à mettre ?
