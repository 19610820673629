import {getDay} from "../../primary/libs/dateStringConverter";
import {t} from "i18next";

export default function currDateToTimeLimitsConverter(interval: string, currDate?: Date, dateFrom ?: string , dateTo ?:string): ({ from: Date, to: Date }) {

    let from: Date = new Date();
    let to: Date = new Date();
    if(currDate){
        switch (interval) {
            case t('words.day') :
                from = new Date(currDate.getFullYear(), currDate.getMonth(), parseInt(getDay(currDate.toDateString()), 0));
                to = new Date(currDate.getFullYear(), currDate.getMonth(), parseInt(getDay(currDate.toDateString()), 0) , 23, 59, 59);
                break;
            case t('words.month') :
                from = new Date(currDate.getFullYear(), currDate.getMonth(), 1, 0, 0, 0);
                to = new Date(currDate.getFullYear(), currDate.getMonth() + 1, 0, 23, 59, 59);
                break;
            case t('words.year') :
                from = new Date(currDate.getFullYear(), 0);
                to = new Date(currDate.getFullYear() + 1, 0, 0, 23, 59, 59);
                break;
            case t('words.period') :
                if(dateFrom && dateTo){
                    from = new Date(dateFrom)
                    to = new Date(dateTo)
                    to.setDate(to.getDate() + 1)
                }
                break;
        }
    }

    return {from, to}

}
