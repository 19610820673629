import React, {Component, ComponentType, ErrorInfo} from 'react';
import GlobalErrorsPage from '../../../Containers/global/Errors/GlobalErrorsPage';
import {ProviderContext, VariantType, withSnackbar} from "notistack";
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";
import {initError} from "../../../../../../corelogic/usecases/error/errorActions";
import {selectError} from "../../../../../../corelogic/usecases/error/errorSelector";
// import SnapshotSerializerPlugin = jest.SnapshotSerializerPlugin;
// import any = jasmine.any;
// import {withStyles} from "@material-ui/core";


type TProps= {
    error: {variant: VariantType, message: string, status: number };
    initError?: any;
    errorComponent?: typeof Component;
} & ProviderContext

type TState= {
    hasError: boolean;
}


class ErrorBoundary extends React.Component<TProps, TState> {
    state = {
        hasError: false,
    };

    static getDerivedStateFromError(error: any) {
        return {hasError: true};
    }

    componentDidUpdate(prevProps: TProps, prevState: TState, snapshot: any) {
        const {error, initError} = this.props;
        if (prevProps.error !== error && (error.message || (error.status && error.status !== 401))) {
            this.props.enqueueSnackbar(error.message || "Une erreur s'est produite. Veuillez réessayer", {
                autoHideDuration: 7000,
                preventDuplicate: true,
                variant: error.variant || new String("Error")
            });
            initError();
        }
    }

    async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.props.enqueueSnackbar("Si le problème persiste n'hésitez pas à nous contacter", {
            autoHideDuration: 7000,
            preventDuplicate: true,
            variant: 'error'
        });
        // Vous pouvez aussi enregistrer l'erreur au sein d'un service de rapport.;
        console.error('error', error);
    }

    render() {
        return this.state.hasError ? this.props.errorComponent || <GlobalErrorsPage/> : this.props.children;
    }
}


let mapStateToProps: (state: { error: any }) => { error: any };

mapStateToProps = createStructuredSelector(
    {
        error: selectError,
    },
);

const mapDispatchToProps = {
    initError,
};

 export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(ErrorBoundary)) as ComponentType;


