import {Route, Switch} from "react-router-dom";
import {APPNAME} from "../../../config/app-config";
import React, {lazy, Suspense} from "react";
import AuthenticatedAndInitializedRoute from "./Components/global/authentication/AuthenticatedAndInitializedRoute";
import UnauthenticatedRoute from "./Components/global/authentication/UnauthenticatedRoute";
import NotFound from "./Containers/global/Errors/NotFound";
import Login from "./Containers/global/authentication/Login"
import RedirectionRoute from "./Components/global/authentication/RedirectionRoute";
import AuthenticatedRoute from "./Components/global/authentication/AuthenticatedRoute";

const EnoConso = lazy(() => import('./Containers'));
const ChangePassword = lazy(() => import('./Containers/global/authentication/ChangePassword'));

export default ({childProps}:any) =>

    <Suspense fallback={<div> Loading...</div>}>
        <Switch>
            <RedirectionRoute exact path ={"/"} user={childProps.user} cProps = {childProps} />
            <AuthenticatedAndInitializedRoute  path={APPNAME.route}  Component={EnoConso}/>
            <UnauthenticatedRoute path="/login" exact Component={Login} props={childProps}/>
            <AuthenticatedRoute path="/initAccount" type = 'first-connexion'  exact  user={childProps.user} Component={ChangePassword} cProps={{ ...childProps, type: "init" }} />
            <AuthenticatedRoute path="/changePassword" type = 'change-pwd'  exact user={childProps.user} Component={ChangePassword} cProps={{ ...childProps, type: "change" }} />
            <Route component={NotFound} />
        </Switch>
    </Suspense>
